import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FaBolt, FaCloudscale, FaRegEnvelope, FaUserCheck, FaVideo } from "react-icons/fa"
import { BiCheckShield } from "react-icons/bi"
import { AiOutlineCloudServer, AiOutlineLock } from "react-icons/ai"
import { GiDiploma, GiMeshNetwork } from 'react-icons/gi'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Hosting" />
    <h1 className='text-center my-5 mx-2'>Website Hosting Services</h1>
    <p className="text-center" style={{ maxWidth: "80%" }}>Lightning fast, hassle free hosting for your <Link to="/services/web-design/">business website</Link> that you can rely on! We'll take care of everything from deployment to maintenance for your site.</p>
    <Container>
      <Row className="py-5 justify-content-around align-items-center"> 
        <Col lg={4}>
          <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow d-flex justify-content-center align-items-center" style={{ width: "fit-content", height: "fit-content" }}>
            <AiOutlineCloudServer className='text-secondary m-3' size='12em' alt="Cloud computing" />
          </div>
        </Col>
        <Col lg={6} className="my-3" style={{ maxWidth: "80%" }}>
          <h2 className="text-center">On the Cloud</h2>
          <span className="d-block pt-3">All of our hosting is on the cloud which means you can rely on:</span>
          <ul className="fa-ul">
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <FaBolt style={{ color: "goldenrod" }} size="1.5em" />
                </Col>
                <Col>
                  Lightning fast hosting speeds for your website, up to 115% faster than the competition
                </Col>
              </Row>
            </li>
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <BiCheckShield style={{ color: "green" }} size="1.5em" />
                </Col>
                <Col>
                  Industry leading, over 99% uptime for your webpage, making it always ready for your customers
                </Col>
              </Row>
            </li>
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <FaCloudscale style={{ color: "red" }} size="1.5em" />
                </Col>
                <Col>
                  Automatically scalable to meet the needs of your customers in real time!
                </Col>
              </Row>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="py-5 justify-content-around align-items-center flex-wrap-reverse">
        <Col lg={6} className="my-3 text-center" style={{ maxWidth: "80%" }}>
          <h2>Utilizing a Global CDN</h2>
          <p className="py-3">A content deliver network (CDN) caches (stores) a site at a specific in its most recent form for quick downloads from servers that are closest to your customers. This speed can also offer a big boost for your <Link to="/services/seo/">website's SEO results</Link>.</p>
        </Col>
        <Col lg={4}>
          <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow d-flex justify-content-center align-items-center" style={{ width: "fit-content", height: "fit-content" }}>
            <GiMeshNetwork className='text-secondary m-3' size='12em' alt="CDN Hosted" />
          </div>
        </Col>
      </Row>
    </Container>
    <CTA header="Ready to get started with your website hosting?" link="/contact/"/>
    <Container>
      <Row className="py-5 justify-content-around align-items-center">
        <Col lg={4}>
          <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow d-flex justify-content-center align-items-center" style={{ width: "fit-content", height: "fit-content" }}>
            <AiOutlineLock className='text-secondary m-3' size='12em' alt="SSL Secured" />
          </div>
        </Col>
        <Col lg={6} className="my-3" style={{ maxWidth: "80%" }}>
          <h2 className="text-center">SSL Encrypted &amp; Secured</h2>
          <p className="py-3">Keep your website and customers safe by encrypting the site traffic. We look after:</p>
          <ul className="fa-ul">
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <GiDiploma style={{ color: "green" }} size="1.5em" />
                </Col>
                <Col>
                  Obtaining, and renewing SSL certificates to ensure their validity
                </Col>
              </Row>
            </li>
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <AiOutlineLock style={{ color: "goldenrod" }} size="1.5em" />
                </Col>
                <Col>
                  Verifying the certificate is applied to the domain and hosting correctly and is functioning as intended
                </Col>
              </Row>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="py-5 justify-content-around align-items-center flex-wrap-reverse">
        <Col lg={6} className="my-3" style={{ maxWidth: "80%" }}>
          <h2 className="text-center">Custom Emails With Your Domain</h2>
          <p className="py-3">Our email services can be added directly to your web hosting, offering you:</p>
          <ul className="fa-ul">
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <FaUserCheck style={{ color: "#abcd03" }} size="1.5em" />
                </Col>
                <Col>
                  A familiar, easy to use platform for sending business emails
                </Col>
              </Row>
            </li>
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <BiCheckShield style={{ color: "green" }} size="1.5em" />
                </Col>
                <Col>
                  Reliable service and storage of your emails
                </Col>
              </Row>
            </li>
            <li>
              <Row className="justify-content-center align-items-center">
                <Col sm={1}>
                  <FaVideo style={{ color: "#00668F" }} size="1.5em" />
                </Col>
                <Col>
                  Additional virtual business tools included such as video conferencing
                </Col>
              </Row>
            </li>
          </ul>        </Col>
        <Col lg={4}>
          <div className="mx-auto border rounded-circle p-4 mb-4 bg-white shadow d-flex justify-content-center align-items-center" style={{ width: "fit-content", height: "fit-content" }}>
            <FaRegEnvelope className='text-secondary m-3' size='12em' alt="CDN Hosted" />
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage