import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Container } from "react-bootstrap"
import { Link } from "gatsby"

const CTA = ({ header, msg, button, link }) => (
    <div className="bg-prim-blue p-5">
        <Container className="py-3">
            <Row className="align-items-center cta">
                <Col lg={9}>
                    <h2 className="text-white">{header}</h2>
                    <span className="text-light">{msg}</span>
                </Col>
                <Col lg={3} className="text-center py-3">
                    <Link to={link} className="btn bg-prim-green primary-blue p-3">{button}</Link>
                </Col>
            </Row>
        </Container>
    </div>
)

CTA.propTypes = {
    header: PropTypes.string,
    msg: PropTypes.string,
    button: PropTypes.string,
    link: PropTypes.string,
}

CTA.defaultProps = {
    header: "Book an appointment today!",
    msg: "We are ready to help you.",
    button: "Book an Appointment!",
    link: "#",
}

export default CTA;